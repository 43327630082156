<template>
    <div class="flex justify-center mt-6">
      <div
        v-for="(planData, key) in plansData"
        :key="key"
        class="mr-5 overflow-hidden border rounded last:mr-0"
        :class="planData.variant === 'primary' ? 'border-red' : 'border-primary-300'"
      >
        <div class="flex flex-col justify-center p-3 bg-primary-100">
          <div class="text-xs text-center">
            {{planData.title}}
          </div>
          <div class="mt-0.75 text-center font-bold">{{planData.price}}</div>
        </div>

        <ul class="px-6 pt-4 pb-2 list-inside">
          <li
            v-for="(feature, key) in planData.features"
            :key="key"
            class="mb-4 text-xs last:mb-0"
          >
            <span v-html="feature" />
          </li>
        </ul>

        <div class="flex justify-center mb-4">
          <Button
            @click="addPlanToCart(planData)"
            :variant="planData.variant"
          >
            {{planData.btnText}}
          </Button>
        </div>
      </div>
    </div>
</template>

<script>
import Button from '@/components/base/buttons/Button.vue'

export default {
  name: 'Plans',
  components: {
    Button
  },
  data () {
    return {
      plansData: [
        {
          title: 'Production Teams',
          price: '$19/ month',
          features: [
            'Upload upto 5 hours/every month',
            'Unlimited audio & transcript exports',
            'Multi user editing & collaboration',
            'Centralized Billing'
          ],
          btnText: 'GET',
          variant: 'primary',
          planId: 'producer_2021'
        },
        {
          title: 'Enterprise plan',
          price: 'Custom pricing',
          features: [
            '<strong>All of production plan +</strong>',
            'Unlimited upload and exports',
            'White-glove editing',
            'Priority support'
          ],
          btnText: 'BOOK A DEMO',
          variant: 'secondary',
          planId: 'enterprise_2022'
        }
      ]
    }
  },
  methods: {
    addPlanToCart (id) {
      this.$emit('addPlanToCart', id)
    }
  }
}
</script>

<style>
</style>
